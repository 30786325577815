import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import {
  ExitToApp,
  ListAlt,
  DirectionsBus,
  People,
  MapOutlined,
  Chat,
  AddLocation,
  ArtTrack,
} from "@material-ui/icons";

// hooks
import { useStores } from "stores";
// assets
import logo from "assets/logo.svg";

type Props = {
  closeMenu: () => void;
};

export function AppMenu({ closeMenu }: Props) {
  const { auth } = useStores();
  const history = useHistory();

  // constants
  const accessType = auth.userData?.access_type;
  const isAdm = accessType === "admin";
  const isOperatorManager = accessType === "operator_manager";

  const handleNavigate = () => {
    history.push("/login");
  };

  const handlSignOut = () => {
    auth.signOut(handleNavigate);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            width: "110px",
            height: "110px",
            padding: "10px",
          }}
          src={logo}
          alt="Logo"
        />
      </div>
      <Divider />
      <MenuList>
        <MenuItem component={Link} to="/" onClick={closeMenu}>
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          <Typography variant="inherit">Rotas</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/buses" onClick={closeMenu}>
          <ListItemIcon>
            <DirectionsBus />
          </ListItemIcon>
          <Typography variant="inherit">Ônibus</Typography>
        </MenuItem>
        {(isAdm || isOperatorManager) && (
          <MenuItem component={Link} to="/route-map" onClick={closeMenu}>
            <ListItemIcon>
              <MapOutlined />
            </ListItemIcon>
            <Typography variant="inherit">Mapa</Typography>
          </MenuItem>
        )}
        {isAdm && (
          <>
            <MenuItem component={Link} to="/users" onClick={closeMenu}>
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <Typography variant="inherit">Usuários</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/feedbacks" onClick={closeMenu}>
              <ListItemIcon>
                <Chat />
              </ListItemIcon>
              <Typography variant="inherit">Feedbacks</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/place-report" onClick={closeMenu}>
              <ListItemIcon>
                <AddLocation />
              </ListItemIcon>
              <Typography variant="inherit">Indicações</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/lost-found" onClick={closeMenu}>
              <ListItemIcon>
                <ArtTrack />
              </ListItemIcon>
              <Typography variant="inherit">Achados & Perdidos</Typography>
            </MenuItem>
          </>
        )}

        {/* <MenuItem component={Link} to="/drivers">
          <ListItemIcon>
            {accessType === "moto" ? <Motorcycle /> : <LocalTaxi />}
          </ListItemIcon>
          <Typography variant="inherit">{menuLabel}</Typography>
        </MenuItem> */}

        {/*
        {(permissionType === "admin" || permissionType === "full") && (
          <MenuItem component={Link} to="/financial">
            <ListItemIcon>
              <LocalAtm />
            </ListItemIcon>
            <Typography variant="inherit">Financeiro</Typography>
          </MenuItem>
        )}
        {permissionType === "full" && (
          <MenuItem component={Link} to="/rates">
            <ListItemIcon>
              <AttachMoney />
            </ListItemIcon>
            <Typography variant="inherit">Preços e Taxas</Typography>
          </MenuItem>
        )}
          */}

        <MenuItem onClick={handlSignOut}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <Typography variant="inherit">Sair</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}
