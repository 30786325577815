import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  CircularProgress,
  Typography,
  Grid,
  Checkbox,
  makeStyles,
  TextField,
} from "@material-ui/core";
import moment from "moment";

// hooks
import { useStores } from "stores";

type Props = {
  open: boolean;
  route: BusRoute;
  onClose(): void;
};

const useStyles = makeStyles({
  paper: {
    minWidth: 650,
  },
});

export function ScheduleModal({ open, route, onClose }: Props) {
  const { busRoutes } = useStores();
  const classes = useStyles();

  const [weeklySchedule, setWeeklySchedule] = useState<ScheduleTime[]>([]);
  const [saturdaySchedule, setSaturdaySchedule] = useState<ScheduleTime[]>([]);
  const [sundaySchedule, setSundaySchedule] = useState<ScheduleTime[]>([]);
  const [hours, setHours] = useState<ScheduleTime[]>([]);
  const [customTime, setCustomTime] = useState("");

  const setSchedule = {
    week: setWeeklySchedule,
    saturday: setSaturdaySchedule,
    sunday: setSundaySchedule,
  };

  const handleSave = async () => {
    await busRoutes.updateRouteSchedule(
      route.id,
      weeklySchedule,
      saturdaySchedule,
      sundaySchedule,
    );
    handleClose();
  };

  const handleClose = () => {
    setWeeklySchedule([]);
    setSaturdaySchedule([]);
    setSundaySchedule([]);
    onClose();
  };

  useEffect(() => {
    if (open && route.id) {
      const hoursData: ScheduleTime[] = [];
      const startTime = moment("04:15", "HH:mm");
      const endTime = moment("23:55", "HH:mm");

      while (startTime.isSameOrBefore(endTime)) {
        hoursData.push({
          time: startTime.format("HH:mm"),
        });
        startTime.add(5, "minutes");
      }

      const {
        weekly_bus_schedule = [],
        saturday_bus_schedule = [],
        sunday_bus_schedule = [],
      } = route;

      const allSchedules = [
        ...weekly_bus_schedule,
        ...saturday_bus_schedule,
        ...sunday_bus_schedule,
      ];

      allSchedules.forEach((schedule) => {
        if (!hoursData.some((hour) => hour.time === schedule.time)) {
          hoursData.push(schedule);
        }
      });

      setWeeklySchedule(weekly_bus_schedule);
      setSaturdaySchedule(saturday_bus_schedule);
      setSundaySchedule(sunday_bus_schedule);
      setHours(hoursData.sort((a, b) => a.time.localeCompare(b.time)));
    }
  }, [open, route]);

  const handleChange = (
    checked: boolean,
    hour: ScheduleTime,
    type: "week" | "saturday" | "sunday",
  ) => {
    setSchedule[type]((prev) =>
      checked ? [...prev, hour] : prev.filter((h) => h.time !== hour.time),
    );
  };

  const handleAddCustomTime = () => {
    if (!customTime) return;

    const time = { time: customTime };
    setHours((prev) =>
      [...prev, time].sort((a, b) => a.time.localeCompare(b.time)),
    );
    setCustomTime("");
  };

  // const handleTimeFrom = (
  //   checked: boolean,
  //   hour: ScheduleTime,
  //   type: "week" | "saturday" | "sunday",
  // ) => {
  //   setSchedule[type]((prev) =>
  //     prev.map((h) =>
  //       h.time === hour.time ? { ...h, from_terminal: checked } : h,
  //     ),
  //   );
  // };

  const renderTimeList = (
    time: ScheduleTime,
    index: number,
    type: "week" | "saturday" | "sunday",
  ) => {
    const schedule = {
      week: weeklySchedule,
      saturday: saturdaySchedule,
      sunday: sundaySchedule,
    }[type];
    const checked = schedule.find((h) => h.time === time.time) !== undefined;

    return (
      <div key={`${type}-${index}`} style={{ display: "flex", width: 190 }}>
        <FormControlLabel
          id="time"
          control={<Checkbox color="secondary" />}
          label={time.time}
          value={time}
          onChange={(_, checked) => handleChange(checked, time, type)}
          checked={checked}
        />
      </div>
    );
  };

  return (
    <Dialog
      classes={classes}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Horários da rota: {route?.route_number} - {route?.name}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          style={{ marginBottom: 20 }}
        >
          <Grid item xs={4}>
            <TextField
              id="time"
              label="Outros horários"
              type="time"
              defaultValue=""
              value={customTime}
              style={{ width: 190 }}
              onChange={(e) => setCustomTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button onClick={handleAddCustomTime}>Adicionar</Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} style={{ minWidth: 600 }}>
          <Grid item xs={4}>
            <Typography>Semanal</Typography>
            {hours.map((hour, index) => renderTimeList(hour, index, "week"))}
          </Grid>
          <Grid item xs={4} style={{ background: "#ddd" }}>
            <Typography>Sábado</Typography>
            {hours.map((hour, index) =>
              renderTimeList(hour, index, "saturday"),
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography>Domingo/Feriado</Typography>
            {hours.map((hour, index) => renderTimeList(hour, index, "sunday"))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          disabled={busRoutes.isRequestLoading}
          color="primary"
        >
          {busRoutes.isRequestLoading ? (
            <CircularProgress size={18} />
          ) : (
            "Salvar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
